import React from "react";
import "./ShowcaseCard.css"
import ButtonGreen from "../../../UI/Buttons/ButtonGreen/ButtonGreen.js";
import { apiURL } from "../../../../axiosApi";

const ShowcaseCard = (props) => {
    return (
        <div className="ShowcaseCard">
            {props.user? 
                    <button className="ShowCaseCard__delete" onClick={props.deleteServer}>X</button>
                    :null
            }
            <div className="ShowcaseCard__img_block">
                <img className="ShowcaseCard__img" src={apiURL +"/uploads/" + props.cardImage} alt="Server" />
            </div>
            <h3 className="ShowcaseCard__title">{props.cardTitle}</h3>
            <div className="ShowcaseCard__description">
                <p className="ShowcaseCard__description_text">{props.cardDescription}</p>
                
            </div>
            <div className="ShowcaseCard__button">
                <a className="likeButtonGreen" href={props.showMore}>Посмотреть характеристики</a>
                {props.user? 
                    <button className="ShowCaseCard__edit" onClick={props.edit}>Редактировать</button>
                    :null
                }
            </div>
            
        </div>
    )
}

export default ShowcaseCard;