import React from "react";
import Container from "../../UI/Container/Container"
import Shapes from "../../../assets/images/shapes.svg"
import "./Equipment.css"
const Equipment = (props) => {
    return (
        <div id="equipment" className="Equipment__wrapper">
            <img className="Equipment__shapes" src={Shapes} alt="Shapes" />
            <Container>
                <div className="Equipment">
                    <h3 className="Equipment__title">Оборудование</h3>
                </div>
            </Container>
        </div>
    )
}

export default Equipment;