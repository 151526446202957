import React, { useEffect, useRef, useState } from "react";
import AppToolbar from "../../Components/UI/AppToolbar/AppToolbar";
import Container from "../../Components/UI/Container/Container";
import PromoLogo from "../../assets/icons/promo_logo.png"
import "./MainPage.css"
import About from "../../Components/MainPage/About/About";
import Equipment from "../../Components/MainPage/Equipment/Equipment";
import Footer from "../../Components/UI/Footer/Footer";
import Showcase from "../../Components/MainPage/Showcase/Showcase";
import { useDispatch, useSelector } from "react-redux";
import { getServers } from "../../Store/services/serversSlice";
import FeedbackForm from "../../Components/MainPage/FeedbackForm/FeedbackForm";
import axios from "../../axiosApi";
import ModalWindow from "../../Components/UI/ModalWindow/ModalWindow";
import ButtonGreen from "../../Components/UI/Buttons/ButtonGreen/ButtonGreen";
import { useLocation } from "react-router-dom";
import UpArrow from "../../Components/UI/UpArrow/UpArrow";
const MainPage = (props) => {
    const dispatch = useDispatch();
    const [company, setCompany] = useState((localStorage.getItem("company")!==null && typeof(localStorage.getItem("company")==="string"))?localStorage.getItem("company"):"forrest");
    const [modalStatus, setModalStatus] = useState(false)
    const [secureModalStatus, setSecureModalStatus] = useState(false)
    const [secure, setSecure] = useState(false);
    const [recaptchaValue, setRecaptchaValue] = useState();
    const [topOfFile, setTopOfFile] = useState(true)

    useEffect(() => {
        const handleScroll = event => {
          window.scrollY === 0? setTopOfFile(true) : setTopOfFile(false)
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
    const captchaRef = useRef();
    useEffect(()=>{
        dispatch(getServers(company));
    }, [dispatch, company])
    useEffect(()=>{
        if(company){
            localStorage.setItem("company", company)
        }
        
    }, [company])
    const servers = useSelector(state=>state.servers.servers)
    const [contactUs, setContactsUs] = useState({
        name: "",
        email: "",
        phone: "",
        message: ""
    })
    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    const query = useQuery();
    const handleClickScroll = (elem) => {
        const element = document.getElementById(elem);
        if (element) {
          // 👇 Will scroll smoothly to the top of the next section
          element.scrollIntoView({ behavior: 'smooth' });
        }
    };


    useEffect(()=>{
        const title = query.get('scroll')
        setTimeout(()=>{
            handleClickScroll(title);
        }, 500)
    }, [query]) 

    const sendEmail = async (data) => {
        captchaRef.current.reset();
        const reqBody = {
            ...data, 
            captcha: recaptchaValue
        }
        const response = await axios.post("/mails", reqBody);

        return response.data;
    } 
    const recaptchaOnChange = (value) => {
        setRecaptchaValue(value)
        setSecure(true)
    }
    return(
        <div className="MainPage"

        >
            {topOfFile? null :<UpArrow onClick = {()=>window.scroll({
                top: 0,
                behavior: "smooth"
            })}/>}
            <div className="Promo__wrapper">
                <AppToolbar 
                    page="main"
                    theme = "dark"
                />
                <Container>
                    <div className="Promo">
                        <img className="Promo__logo" src={PromoLogo} alt="PromoLogo"/> 
                        <h1 className="Promo__title">Каталог серверного оборудования</h1>   
                        <button onClick={()=>handleClickScroll("contactus")} className="Promo__button">Заказать решение</button>
                    </div>    
                </Container>
            </div>
            <About/>
            <Equipment/>
            <Showcase 
                servers={servers}
                setForrest = {()=>{setCompany("forrest")}}
                setNimbus = {()=>{setCompany("nimbus")}}
                company={company}
            />
            <FeedbackForm
                nameValue =  {contactUs.name}
                nameOnChange = {(e)=>setContactsUs({...contactUs, name: e.target.value})}
                phoneValue = {contactUs.phone}
                phoneOnChange = {(e)=>setContactsUs({...contactUs, phone: e.target.value})}
                emailValue = {contactUs.email}
                emailOnChange = {(e)=>setContactsUs({...contactUs, email: e.target.value})}
                messageValue = {contactUs.message}
                messageOnChange = {(e)=>setContactsUs({...contactUs, message: e.target.value})}
                onChangeCaptcha = {recaptchaOnChange}
                captchaRef={captchaRef}
                formOnSubmit = {async (e)=>{
                    e.preventDefault();
                    if(secure){
                        await sendEmail(contactUs);
                        await setModalStatus(true);
                        await setContactsUs(
                            {
                                name: "",
                                email: "",
                                phone: "",
                                message: ""
                            }
                        )
                        await setSecure(false)
                    }else{
                        setSecureModalStatus(true)
                    }
              }}
            />
            <Footer colorful={false}/>
            <ModalWindow
                bgOnClick = {()=>setModalStatus(false)}
                modalStatus = {modalStatus}
            >
                <div className="Feedback__modal">
                    <div className="Feedback__modal_title">
                        Спасибо за обращение!
                    </div>
                    <div className="Feedback__modal_text">
                        С вами скоро свяжется наш менеджер
                    </div>
                    <ButtonGreen onClick={()=>setModalStatus(false)}>Хорошо</ButtonGreen>
                </div>
                
            </ModalWindow>
            <ModalWindow
                bgOnClick = {()=>setSecureModalStatus(false)}
                modalStatus = {secureModalStatus}
            >
                <div className="Feedback__modal">
                    <div className="Feedback__modal_title">
                        Что-то пошло не так!
                    </div>
                    <div className="Feedback__modal_text">
                        Вам нужно подтвердить, что вы не робот
                    </div>
                    <ButtonGreen onClick={()=>setSecureModalStatus(false)}>Хорошо</ButtonGreen>
                </div>
                
            </ModalWindow>
        </div>
    )
}

export default MainPage;