import React from "react";
import { useNavigate } from "react-router-dom";
import "./AddServer.css"


const AddServer = (props) => {
    const navigate = useNavigate()
    return (
        <div onClick={()=>navigate("/addserver")} className="AddServer">
            <div className="AddServer__title">Добавить сервер</div>
        </div>
    )
}

export default AddServer;