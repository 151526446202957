import React from "react";
import Container from "../../UI/Container/Container"
import "./Showcase.css"
import ForestLogo from "../../../assets/icons/forest.svg"
import NimbusLogo from "../../../assets/icons/nimbus.png"
import ShowcaseCard from "./ShowcaseCard/ShowcaseCard.js";
import { useNavigate } from "react-router-dom";
import AddServer from "../../Admin/AddServer/AddServer";
import { useDispatch } from "react-redux";
import { deleteServer } from "../../../Store/services/serversSlice";

const Showcase = (props) => {
    const servers = props.servers;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    return (
        <div className="Showcase__wrapper">
            <div className="Showcase__header">
                <Container>
                    <div className="Showcase__header_inner">
                        <div className="Showcase__header_title">Выберите бренд-сервер</div>
                        <div className="Showcase__header_logos">
                            <div onClick={props.setForrest} className={`Showcase__header_logo ${props.company==="forrest"?"Showcase__header_logo_active": ""}`}>
                                <img className="Showcase__header_img forest" src={ForestLogo} alt="forest" />
                            </div>
                            <div onClick={props.setNimbus} className={`Showcase__header_logo ${props.company==="nimbus"?"Showcase__header_logo_active": ""}`}>
                                <img className="Showcase__header_img nimbus" src={NimbusLogo} alt="Nimbus" />
                            </div>
                        </div>
                        
                    </div>
                    
                </Container>
            </div>
            <Container>
                <div className="Showcase">
                    {props.company ==="forrest"?
                        <h3 className="Showcase__title">Каталог серверного оборудования Forest</h3>:
                        props.company ==="nimbus"?
                        <h3 className="Showcase__title">Каталог серверного оборудования Nimbus</h3>:
                        null
                    }
                </div>
                <div className="ShowCase__servers">
                {
                    servers?.map((server) => {
                        return(
                            <ShowcaseCard 
                                user={props.user}
                                key={server._id}
                                cardImage = {server.image}
                                cardDescription={server.description}
                                cardTitle={server.title}
                                showMore={"/server/"+server._id}
                                edit={()=>{navigate("/edit/"+server._id)}}
                                deleteServer={()=>{dispatch(deleteServer(server._id))}}
                            />
                        )
                    })
                }
                {props.user?
                    <AddServer/>
                    :null
                }
                </div>
                
                
                
            </Container>
        </div>
    )
}

export default Showcase;