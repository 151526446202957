import React, { useEffect, useState } from "react";
import Container from "../../Components/UI/Container/Container";
import "../MainPage/MainPage.css"
import { useDispatch, useSelector } from "react-redux";
import { getServerById, getServers, setServer, updateServer } from "../../Store/services/serversSlice";
import FormInput from "../../Components/UI/Form/FormInput/FormInput";
import Select from 'react-select';
import { useNavigate, useParams } from "react-router-dom";
import "./Edit.css"
import { nanoid } from "@reduxjs/toolkit";
import ButtonGreen from "../../Components/UI/Buttons/ButtonGreen/ButtonGreen";
import axios from "../../axiosApi";
import FileInput from "../../Components/UI/Form/FileInput";
const Edit = (props) => {
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const server = useSelector(state=>state.servers.currentServer)
    const [requestSent, setRequestSent] = useState(false);
    const [editedServerState, setEditedServerState] = useState ()
    const fetch = async ()=>{
        const response = await axios.get('/servers/'+params.id);
        return response.data;
    }
    useEffect(()=>{
        if(requestSent){
            return;
        }
        fetch().then(data=>{
            setEditedServerState(data)
        });
        setRequestSent(true)
    }, [dispatch, params.id, server, requestSent, editedServerState])
    
    const addSpec = () =>{
        const stateCopy = {...editedServerState};
        stateCopy.specifications.push({
            title: "",
            description: ""
        })
        setEditedServerState(stateCopy)
    }
    const updateServe = async () => {
        const formData = new FormData();
        Object.keys(editedServerState).forEach(key => {
            if (typeof editedServerState[key] === 'object' && editedServerState[key] !== null) {
                formData.append(key, JSON.stringify(editedServerState[key]))
            } else {
                formData.append(key, editedServerState[key]);
            }
        });
        formData.append("image", editedServerState.image)
        await dispatch(updateServer({
            id: params.id,
            data: formData,
            navigate
        }))
    }
    const spliceSpec = (index) =>{
        const stateCopy = {...editedServerState};
        stateCopy.specifications.splice(index, 1)
        setEditedServerState(stateCopy)
    }
    const options = [
        { value: 'forrest', label: 'forest' },
        { value: 'nimbus', label: 'nimbus' },
      ];
    return(
        <div className="Edit">
            <Container>
                <div className="Edit__title">
                    Редактировать сервер
                </div>
                <form className="Edit__form">
                    <FormInput
                        label="Название сервера"
                        value = {editedServerState?.title || ""}
                        onChangeHandler={(e)=>{
                            setEditedServerState({...editedServerState, title: e.target.value})
                        }}
                    />

                    <FormInput
                        label="Описание сервера"
                        value = {editedServerState?.description || ""}
                        onChangeHandler={(e)=>{setEditedServerState({...editedServerState, description: e.target.value})}}
                    />
                    <span>Компания</span>
                    <Select
                        className="Edit__select"
                        placeholder={editedServerState?.company}
                        options={options}
                        defaultValue = {editedServerState?.company}
                        onChangeHandler={(e)=>{setEditedServerState({...editedServerState, company: e.target.value})}}
                    />
                    <div className="Edit__title">Фото</div>
                    <FileInput
                        image={editedServerState?.image}
                        title = "Изображение сервера"
                        onChange = {(e) => {
                            setEditedServerState({...editedServerState, image: e.target.files[0]});
                        }}
                    />
                    {
                        editedServerState?.specifications?.map((spec, index) => {
                            return(
                                <div key={index} className="Add_spec">
                                    <div  className="Edit__spec">
                                        <FormInput 
                                            placeholder="Название"
                                            value={spec.title}
                                            onChangeHandler={(e)=>{setEditedServerState({...editedServerState}, editedServerState.specifications[index].title = e.target.value)}}
                                            required    
                                        />
                                        <FormInput 
                                            required
                                            placeholder="Описание"
                                            value={spec.description}
                                            onChangeHandler={(e)=>setEditedServerState({...editedServerState}, editedServerState.specifications[index].description = e.target.value)}
                                        />
                                        
                                    </div>
                                    <button className="delete_spec" onClick={()=>{spliceSpec(index)}}>X</button>
                                </div>
                                
                            )
                        })
                    }
                    <button className="Add_spec_button" type="button" onClick={()=>addSpec()}>Добавить характеристику</button>
                    <ButtonGreen
                        onClick = {updateServe}
                    >
                        Изменить
                    </ButtonGreen>
                </form>
            </Container>
        </div>
    )
}

export default Edit;