import React, { useEffect } from "react";
import AppToolbar from "../../Components/UI/AppToolbar/AppToolbar";
import Footer from "../../Components/UI/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getServerById } from "../../Store/services/serversSlice";
import ServerInfo from "../../Components/ServerPage/ServerInfo/ServerInfo";
import { useNavigate, useParams } from "react-router-dom";
import ServerSpec from "../../Components/ServerPage/ServerSpec/ServerSpec";
import ButtonGreen from "../../Components/UI/Buttons/ButtonGreen/ButtonGreen";
import "./ServerPage.css"
import Container from "../../Components/UI/Container/Container";
const ServerPage = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    useEffect(()=>{
        window.scroll({
            top: 0,
            behavior: "auto"
        })
        dispatch(getServerById(params.id));
    }, [dispatch, params.id])
    const currentServer = useSelector(state=>state.servers.currentServer)
    return(
        <div className="ServerPage">
            <AppToolbar 
                theme = "white"
            />
            <ServerInfo 
                serverTitle = {currentServer.title}
                serverDescription = {currentServer.description}
                serverImage = {currentServer.image}
            />
            <ServerSpec
                specs={currentServer.specifications}
            />
            <Container>
                <div className="ServerPage__check_price">
                    <div className="ServerPage__check_price_button">
                            <ButtonGreen 
                                onClick = {()=>{navigate("/?scroll=contactus")}}
                            >Узнать стоимость
                            </ButtonGreen>
                    </div>
                    
                </div>
            </Container>
            <Footer colorful={true}/>
        </div>
    )
}

export default ServerPage;