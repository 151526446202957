import React from "react";
import Container from "../../UI/Container/Container"
import AboutImg from "../../../assets/images/about.jpg"
import "./About.css"
const About = (props) => {
    return (
        <div id="about" className="About__wrapper">
            <div className="About">
                <Container>
                    <div className="About__inner">
                        <div className="About__inner_content">
                            <div className="About__title">О нас</div>
                            <div className="About__subtitle">Ведущий интегратор ИТ и ИБ</div>
                            <div className="About__text">
                            <ul className="About__text_list">
                                <li>Казахстанский товаропроизводитель, «Сертификат происхождения товаров по форме CT-KZ»</li>
                                <li>11 лет на лидирующих позициях ИТ и ИБ рынка</li>
                                <li>100+ успешных проектов</li>
                                <li>Резидент AstanaHub</li>
                                <li>Аккредитованный партнер ЕБРР, Всемирного Банка</li>
                                <li>Официальный партнер более 20-ти ведущих производителей по ИБ и ИТ</li>
                                <li>Лучшая команда инженеров для решения Ваших задач по ИБ и ИТ</li>
                                <li>Мы обеспечим Вам первую линию технической поддержки.</li>
                                <li>90% задач решаются без привлечения вендора</li>
                            </ul>
                            </div>
                        </div>
                        <img className="About__img" src={AboutImg} alt = "About" />
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default About;
