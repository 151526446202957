import React from "react";
import Container from "../../UI/Container/Container"
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import "./Footer.css"
const Footer = (props) => {

    const defaultState = {
        center: [43.212180, 76.959470],
        zoom: 10,
      };
    return (
        <div id="contacts" className={`Footer__wrapper ${props.colorful? "Footer__colorful" : ""}`}>
            <Container>
                <div className="Footer">
                    <h3 className="Footer__title">ТОО “Life2Win Consulting”</h3>
                    <div className="Footer__info">
                        <div className="Footer__info_text">
                            <p>Головной офис</p>
                            <div className="Footer__contacts">
                                <div className="Footer__contacts_text Footer__location">г.Алматы ул. Игишева, 76</div>
                                <div className="Footer__contacts_text Footer__phone">+7-(727)-356-99-88</div>
                                <div className="Footer__contacts_text Footer__email">Info@l2w.kz</div>
                            </div>
                        </div>
                        <div className="Footer__map">
                            <YMaps>
                                <Map defaultState={defaultState} width={"100%"}>
                                    <Placemark geometry={[43.212180, 76.959470]} />
                                </Map>
                            </YMaps>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Footer;