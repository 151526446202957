import React from "react";
import ButtonFilledGreen from "../../UI/Buttons/ButtonFilledGreen/ButtonFilledGreen";
import Container from "../../UI/Container/Container"
import "./FeedbackForm.css"
import ReCAPTCHA from "react-google-recaptcha";
import { captcha_key } from "../../../config";
const FeedbackForm = (props) => {
    return (
        <div id="contactus" className="FeedbackForm__wrapper">
            <Container>
                <div className="FeedbackForm">
                    <h3 className="FeedbackForm__title">Свяжитесь с  нами</h3>
                    <form onSubmit={props.formOnSubmit} className="FeedbackForm__form">
                        <input required className="FeedbackForm__input" placeholder="Ваше имя*"                         value={props.nameValue} onChange={props.nameOnChange}/>
                        <input required className="FeedbackForm__input" type = "tel" placeholder="Ваш номер телефона*"  value={props.phoneValue} onChange={props.phoneOnChange}/>
                        <input required className="FeedbackForm__input" type="email" placeholder="Ваш e-mail*"          value={props.emailValue} onChange={props.emailOnChange}/>
                        <textarea required className="FeedbackForm__input" placeholder="Оставьте свое сообщение*"       value={props.messageValue} onChange={props.messageOnChange}/>
                        {(props.nameValue && props.phoneValue && props.emailValue && props.messageValue)? 
                        <ReCAPTCHA
                            sitekey={captcha_key}
                            onChange={props.onChangeCaptcha}
                            ref={props.captchaRef}
                        />: null
                        }
                        <ButtonFilledGreen type="submit">Отправить</ButtonFilledGreen>
                    </form>
                </div>
            </Container>
        </div>
    )
}

export default FeedbackForm;